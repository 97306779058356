// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-accounting-services-js": () => import("./../../../src/pages/services/accounting-services.js" /* webpackChunkName: "component---src-pages-services-accounting-services-js" */),
  "component---src-pages-services-company-incorporation-js": () => import("./../../../src/pages/services/company-incorporation.js" /* webpackChunkName: "component---src-pages-services-company-incorporation-js" */),
  "component---src-pages-services-deregistration-js": () => import("./../../../src/pages/services/deregistration.js" /* webpackChunkName: "component---src-pages-services-deregistration-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-obtaining-tax-certificates-js": () => import("./../../../src/pages/services/obtaining-tax-certificates.js" /* webpackChunkName: "component---src-pages-services-obtaining-tax-certificates-js" */),
  "component---src-pages-services-registration-js": () => import("./../../../src/pages/services/registration.js" /* webpackChunkName: "component---src-pages-services-registration-js" */),
  "component---src-pages-services-tax-consulting-and-tax-compliance-js": () => import("./../../../src/pages/services/tax-consulting-and-tax-compliance.js" /* webpackChunkName: "component---src-pages-services-tax-consulting-and-tax-compliance-js" */)
}

